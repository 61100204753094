
.maincontent {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

}
.pic {
    width: 100%;
    height: auto;
    padding: .5rem;
}

.anchor {
    text-decoration: none !important;
    color: #1189dd;

}
.spacing {
    margin: 1rem 0rem !important;
}

.paragraphs {
    font-size: 23px;
    font-family: Arial;
}

.background {
    background-color: whitesmoke;
    padding: 1rem;
    
}