
.navigation {
   background-color: whitesmoke; 
   width: 100%;
}
.navigation ul{
text-align: center;
padding-top: 4rem;
}

.navigation li {
    list-style: none;
    display: inline;
    margin: 1rem;
    font-weight: bold;
}

.navigation li:hover {
    list-style: none;
    display: inline;
    margin: 1rem;
    font-weight: bold;
    color: blue;
    cursor: pointer;
}

.color{
    color: #1189dd !important;
    font-size: large !important;
    font-weight: 600 !important;
    
}

.links {
    padding-left: 4rem !important;
}