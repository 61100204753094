
.section {
    background-color: black;
    color: #1189dd;
    padding: 1rem;
}

.anchor {
    text-decoration: none !important;
    color: #1189dd;

}