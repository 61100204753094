
.content {
    margin: 1rem;
}

.paragraphs {
    font-size: 23px;
    font-family: Arial;
}

.quote {
    font-size: 21px;
    font-family: Arial;
    text-align: center;
}

.whitesmoke {
    background-color: whitesmoke;
}

.pic {
    width: 100%;
    height: auto;
}

.midColumn {
    padding: 2rem;
}

.btn {
  padding: 1rem; 
  width: 40%;
  display: block;
  margin: auto;
  border-radius: 30px;
  border: black 2px solid;
  color: black;
  text-decoration: none !important;
  font-weight: bold !important;
}

.anchor {
    text-decoration: none !important;
    font-weight: bold !important;

}

.gray {
    color: gray;
}

.spacing {
    text-align: center;
    padding: 2rem;
}

.boldheader {
    font-weight: bold;
    padding: 2rem;
}

.padding {
    padding: 2rem;
}

.bottomptags {
    font-size: 20px;
    font-weight: 500;
}